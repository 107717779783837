<template>
  <div class="font">
    <ayl-berad-nav :nav="nav" />
    <div class="content-box mt20px">
      <div class="content-main mt0px">
        <el-tabs v-model="activeName">
          <el-tab-pane label="人员当班预警" name="first">
            <ayl-table-with-query-object :table="table"></ayl-table-with-query-object>
          </el-tab-pane>
          <el-tab-pane label="车辆当班预警" name="second">
            <ayl-table-with-query-object :table="vehiclesTable"></ayl-table-with-query-object>
          </el-tab-pane>
          <el-tab-pane label="作业预警设置" name="three">
            <ayl-table-with-query-object
              :table="warningSettingTable"
              @selection-change="selectionTable"
            >
              <div slot="ctrl-button">
                <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
                <el-button
                  class="ml40px"
                  type="info"
                  style="float: right;"
                  @click="onDelete"
                  plain
                >删除</el-button>
              </div>
            </ayl-table-with-query-object>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
      :width="widthForDialog"
    ></ayl-dialog>
  </div>
</template>

<script>
import Delete from "@/mixins/delete";
export default {
  mixins: [Delete],
  data() {
    // eslint-disable-next-line no-unused-vars
    const vm = this;
    return {
      debug: 1,
      nav: [{ name: "作业预警管理" }],
      //弹窗有关
      viewUrlForDialog: ``,
      isShowDialog: false,
      dataForDialog: null,
      widthForDialog: "40%",

      //删除参数
      deleteAPI_Id: [
        this.$api_hw.operationSafetyManagement_jobPreWarningBatchDelete,
        "idList",
        "id",
        "warningSettingTable"
      ],

      common_getBidNameAndIdList: [true, 0, null], //标段管理下拉
      activeName: "first",
      isBidId: null,

      table: {
        api: vm.$api_hw.operationSafetyManagement_jobPreWarningEventPage,
        query: {
          query: {
            bidId: null, //标段
            workingAreaId: null, //作业网格
            startTime: null, //开始时间
            endTime: null, // 结束时间
            eventType: null, //预警类型
            eventStatus: null, //预警状态
            queryContent: null //车牌号/姓名
          },
          queryType: 0
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "query.bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "作业网格",
            model: "query.workingAreaId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "dateSection",
            title: "开始时间",
            isOptions: true,
            //model: [],//沿用以前的套路，日期选择不用传model，应传modelName - by 马波
            modelName: ["query.startTime", "query.endTime"]
          },
          {
            type: "select",
            title: "预警类型",
            model: "query.eventType",
            placeholder: "请选择",
            option: this.$enums_hw.eventTypeOption.persons
          },
          {
            type: "select",
            title: "预警状态",
            model: "query.eventStatus",
            placeholder: "请选择",
            option: this.$enums_hw.eventStatusOption.list
          },
          {
            type: "input",
            model: "query.queryContent",
            placeholder: "车牌号"
          }
        ],
        columns: [
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "姓名",
            key: "subjectName"
            // width: "120px"
          },
          {
            title: "标段名称",
            key: "bidName"
            // width: "120px"
          },
          {
            title: "作业网格",
            showTooltip: true,
            key: "workingArea"
          },
          {
            title: "车辆类型",
            showTooltip: true,
            key: "innerTypeDes"
          },
          {
            title: "预警事件",
            // width: "100px",
            key: "eventTypeDes"
          },
          {
            title: "预警时间",
            key: "eventTime",
            filter: "str2ymd",
            width: "170px"
          },
          {
            title: "班次时段",
            width: "120px",
            key: "shiftStartTime" //shiftEndTime
          },
          {
            title: "预警状态",
            // width: "120px",
            key: "eventStatusDes"
          },
          {
            title: "操作",
            align: "center",
            width: "155px",
            render(h, ctx) {
              return h("div", [
                h(
                  "el-button",
                  {
                    style: {
                      color: ctx.row.eventStatus === 0 ? "#1ABC9C" : ""
                    },
                    props: {
                      type: "text",
                      disabled: ctx.row.eventStatus === 0 ? false : true
                    },
                    on: {
                      click: vm.onDetails.bind(this, ctx.row)
                    }
                  },
                  "已处理"
                )
              ]);
            }
          }
        ]
      },

      //车辆当班预警
      vehiclesTable: {
        api: vm.$api_hw.operationSafetyManagement_jobPreWarningEventPage,
        query: {
          query: {
            bidId: null, //标段
            workingAreaId: null, //作业网格
            startTime: null, //开始时间
            endTime: null, // 结束时间
            eventType: null, //预警类型
            eventStatus: null, //预警状态
            queryContent: null //车牌号/姓名
          },
          queryType: 1
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "query.bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "作业路线",
            model: "query.workingAreaId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "dateSection",
            title: "开始时间",
            isOptions: true,
            model: [],
            modelName: ["query.startTime", "query.endTime"]
          },
          {
            type: "select",
            title: "预警类型",
            model: "query.eventType",
            placeholder: "请选择",
            option: this.$enums_hw.eventTypeOption.cars
          },
          {
            type: "select",
            title: "预警状态",
            model: "query.eventStatus",
            placeholder: "请选择",
            option: this.$enums_hw.eventStatusOption.list
          },
          {
            type: "input",
            model: "query.queryContent",
            placeholder: "车牌号"
          }
        ],
        columns: [
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "车牌号",
            key: "subjectName",
            width: "120px"
          },
          {
            title: "标段名称",
            key: "bidName",
            width: "120px"
          },
          {
            title: "作业网格",
            showTooltip: true,
            key: "workingArea"
          },
          {
            title: "车辆类型",
            showTooltip: true,
            key: "innerTypeDes"
          },
          {
            title: "预警事件",
            // width: "100px",
            key: "eventTypeDes"
          },
          {
            title: "预警时间",
            key: "eventTime",
            filter: "str2ymd",
            width: "170px"
          },
          {
            title: "班次时段",
            width: "120px",
            key: "shiftStartTime" //shiftEndTime
          },
          {
            title: "预警状态",
            // width: "120px",
            key: "eventStatusDes"
          },
          {
            title: "操作",
            width: "155px",
            render(h, ctx) {
              return h("div", [
                h(
                  "el-button",
                  {
                    style: {
                      color: ctx.row.eventStatus === 0 ? "#1ABC9C" : ""
                    },
                    props: {
                      type: "text",
                      disabled: ctx.row.eventStatus === 0 ? false : true
                    },
                    on: {
                      click: vm.onDetails.bind(this, ctx.row)
                    }
                  },
                  "已处理"
                )
              ]);
            }
          }
        ]
      },

      //作业预警设置
      warningSettingTable: {
        api: vm.$api_hw.operationSafetyManagement_jobPreWarningPage,
        query: {
          query: { bidId: null }
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "query.bidId",
            placeholder: "请选择",
            option: []
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "标段名称",
            key: "bidName",
            width: "120px"
          },
          {
            title: "养护企业",
            showTooltip: true,
            key: "bidMaintainUnit"
          },
          {
            title: "迟到预警（分钟）",
            width: "160px",
            key: "personLate"
          },
          {
            title: "异常离岗预警（分钟）",
            width: "160px",
            key: "personOff"
          },

          {
            title: "超时静止预警（分钟）",
            width: "160px",
            key: "personRest"
          },

          {
            title: "轨迹一致预警（分钟）",
            width: "160px",
            key: "personTrackConsistent"
          },
          {
            title: "人员作业里程不足预警",
            width: "160px",
            key: "personInsufficientWork"
          },
          {
            title: "车辆机扫晚点预警（分钟）",
            width: "200px",
            key: "carLateScanning"
          },
          {
            title: "车辆机洗晚点预警（分钟）",
            width: "200px",
            key: "carLateWashing"
          },
          {
            title: "操作",
            width: "155px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.onEdit.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      }
    };
  },

  watch: {
    table: {
      handler(n, o) {
        this.$nextTick(() => {
          if (this.table.query.query.bidId) {
            // this.getWorkingArea(this.table.query.query.bidId);
            this.isBidId = this.table.query.query.bidId;
          }
        });
        return false;
      },
      deep: true,
      immediate: true
    },
    vehiclesTable: {
      handler(n, o) {
        this.$nextTick(() => {
          if (this.vehiclesTable.query.query.bidId) {
            this.isBidId = this.vehiclesTable.query.query.bidId;
          }
        });
        return false;
      },
      deep: true,
      immediate: true
    },
    isBidId() {
      if (this.isBidId) {
        this.getWorkingArea(this.isBidId);
      }
    }
  },

  methods: {
    // 动态获取下拉数据
    getWorkingArea(id) {
      this.$api_hw
        .operationSafetyManagement_jobPreWarningEventListWorkingAreas({
          id: id,
          queryType: this.activeName == "first" ? 0 : 1
        })
        .then(res => {
          this.log(res);
          this.activeName == "first"
            ? (this.table.searchData[1].option = [...res])
            : (this.vehiclesTable.searchData[1].option = [...res]);
        });
    },

    //新增
    onAdd() {
      //传views的url
      this.viewUrlForDialog =
        "/operation-safety-management/operational-early-warning-management/add";
      this.widthForDialog = "58%";
      //传参给弹窗
      this.dataForDialog = {};
      this.isShowDialog = true; //显示弹窗
    },
    //编辑
    onEdit(val) {
      //传views的url
      this.viewUrlForDialog =
        "/operation-safety-management/operational-early-warning-management/edit";
      this.widthForDialog = "58%";
      //传参给弹窗
      this.dataForDialog = {
        id: val.id
      };
      this.isShowDialog = true; //显示弹窗
    },

    //已处理
    async onDetails(val) {
      await this.$api_hw
        .operationSafetyManagement_jobPreWarningEventDeal({ id: val.id })
        .then(res => {
          this.$message({
            message: "已处理成功",
            type: "success"
          });
          this.$search(this.table);
          this.$search(this.vehiclesTable);
        });
    }
  },

  async mounted() {
    // await this.handleClick();
    BUS.$on(BUSEVENT.REFRESH_OPERATIONAL_EARLY_WARNING_MANAGEMENT, () => {
      this.$search(this.warningSettingTable);
    });
  }
};
</script>
<style lang='sass' scoped>
.font
  font-family: MicrosoftYaHei,
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C

/deep/ .table-view-edit
  padding-right: 10px
  color: #99c0ff

/deep/ .el-form--inline .el-form-item
  margin-right: 40px
/deep/ .el-table th > .cell
  color: rgba(41, 41, 41, 1)
  font-size: 14px

/deep/ .el-table .cell
  color: rgba(102, 102, 102, 1)
  font-size: 14px

.pagination
  width: 100%
  float: right
/deep/ .content-box .content-main
  padding: 24px 24px 74px
.content-box /deep/ .el-form-item
  width: auto
/deep/ .el-button--primary 
  color: #FFFFFF
  background-color: $-color-primary-3
  border-color: $-color-primary-3
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner
  background-color: $-color-primary-3
  border-color: $-color-primary-3
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label 
  color: $-color-primary-3
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner
  background-color: $-color-primary-3
  border-color: $-color-primary-3
/deep/.el-tabs__item.is-active
  color: $-color-primary-3
/deep/.el-tabs__active-bar
  background-color: $-color-primary-3
/deep/.el-tabs__item:hover
  color: $-color-primary-3
  cursor: pointer
.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px
</style>